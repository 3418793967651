import React from "react";
import "./DigitalDirector.css";

import digitalimg from "../../../assets/newpage/digitall.png";
import bggold from "../../../assets/newpage/digitalbg.png";
import bg from "../../../assets/newpage/Unparalleled.png";
import arrow from "../../../assets/newpage/arrow.svg"


import Vid1 from "./Product1"
import Product2 from "./Video2"
import Product3 from "./Video3"

const DigitalDirector = () => {
  return (
    <div className="d-flex justify-content-center position-relative overflow-hidden">
      <div className="digitalmain ">
        <img className="digital-bg" src={bggold} alt="" />
        {/* row1 */}
        <div
          className=" digital-row1"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
 {/* right */}
 <div className="digital-2nd-col  digital-director-mob-img">
            <img className="digitalimg" src={digitalimg} alt="" />
          </div>

          {/* left */}
          <div className="digital-first-col">
            <h1 className="digitalheading">Introducing Your New </h1>
            <h2 className="digitaltitle">Digital Director</h2>
            <p className="para digital-para">
              Immerse yourself in the creative vision of our latest talent
              acquisition: Jay Ford. A visionary videographer whose cinematic
              alchemy has enhanced brands like Coca-Cola, Fanatics, and Intel.
              Navigate through the following vignettes showcasing Jay's
              collaborations with iconic clients:
            </p>
          </div>
          {/* right */}
          <div className="digital-2nd-col digital-director-desktop-img ">
            <img className="digitalimg" src={digitalimg} alt="" />
          </div>
        </div>

        {/* row2 */}
        <div
          className=" digital-row2"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          {/* col1 */}
          <Vid1/>

          {/* col2 */}
          <Product3/>
         
          {/* col3 */}
          <Product2/>

        </div>

        {/* bottom */}
        <div
          className="digital-bottom"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
        >
          <img className="digital-bottom-bg" src={bg} alt="" />

          <div className="">
            <h1 className="digitalheading text-center">
            Turn Viewers Into Advocates
            </h1>
            <p className="digital-para-b text-center" style={{maxWidth:"800px",marginLeft:"auto",marginRight:"auto"}}>
            Our approach blends creative storytelling with strategic SEO, ensuring your message not only reaches but resonates, turning clicks into conversions.
            </p>

            <div className="d-flex justify-content-center gap-3 mr-3 button-relative">
            <a href="/booking">
            <button className="btncontact ml-2">
            Lets Discuss
            <img className="ml-2 button-arrow" src={arrow} alt=""  />

            </button>
          </a>
        </div>


           
          </div>
        </div>
        {/* <Bottom/> */}
      </div>
    </div>
  );
};

export default DigitalDirector;
